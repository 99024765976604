.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gradient__bg {
  /* ff 3.6+ */
  background: -moz-radial-gradient(circle at 30% -100%, #00ffdf 25%, #8afff0 85%, #f0fffd 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(circle at 30% -100%, #00ffdf 25%, #8afff0 85%, #f0fffd 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(circle at 30% -100%, #00ffdf 25%, #8afff0 85%, #f0fffd 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(circle at 30% -100%, #100818 55%, #2a3f43 75%, #090b0e 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 30% -100%, #100818 55%, #1133a6 75%, #090b0e 100%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}
