
form{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
    max-width: 400px;
    
}
form h1{
    font-family: var(--font-family);
        font-size: 40px;
        font-weight: 900;
        text-transform: uppercase;
        margin-left: 17%;
        margin-bottom: 10%;
}

form label{
    margin-bottom: 0.5rem;
    color: #eee;
    font-size: 25px;
    font-weight: 600;
}

form input, textarea{
    margin-bottom: 1rem;
    padding: 10px 18px ;
    font-size: 1.7rem;
    font-family: var(font-family);
    background: rgba(255,255,255,.1);
    border:rgba(255, 255, 255, .1) ;
    border-radius: 10px;
    color: #f4f4f4;
}
.form button {
    background: rgba(4, 53, 143, 0.71);
    border: rgba(255, 255, 255, .1);
    border-radius: 10px;
    font-size: 50px;
    color: var(--main-color);
    cursor: pointer;
    text-transform: capitalize;

}

.form button:hover {
    filter: brightness(1.15);
}
