.gpt3__services{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2rem ;
        position: relative;
        width: 100%;
}
.gpt3__services h1{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 5rem;
        margin-top: 180px;
}
.gpt3__services-padding {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem 6rem;
}

.gpt3__services-padding-serv{
    display: flex;
    flex-direction: column;
    margin: 30px 20px;
    border: 0px solid;
    border-radius: 25px;
    box-shadow: 0px 20px 60px rgba(7, 0, 0, 0.5);
    background-color: white;
    width: 300px;
    height: 400px;
    align-items: center;
    text-align: center;
    color: black;
}
.gpt3__services-padding-serv h2{
    font-size: 35px;
    font-weight: 500;
}
.gpt3__services-padding-serv p{
    font-size: 20px;
    font-weight: 400;
}
.gpt3__services-padding-serv .icons {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 35px;
    background-color: #03071E;
}
.gpt3__services-padding-serv i{
    color: #F6C445;
    font-size: 65px;
    font-weight: bolder;
}
