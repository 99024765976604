.gpt3__navbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 150px;
        padding: 3rem  0rem 2rem 0rem ;
        z-index: 1;
}

.gpt3__navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1;
}
.gpt3__navbar-links_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
}
.gpt3__navbar-links_logo h1 {
    font-family: var(--font-family);
    font-size: 70px;
    font-weight: 900;
    text-transform:uppercase;
}
.gpt3__navbar-links_logo p{
    font-family: var(--font-family);
        font-size: 25px;
        font-weight: 900;
        text-transform: capitalize;
}
.gpt3__navbar-links_logo img {
    width: 62px;
    height: 16px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
    z-index: 2;
    flex: 1;
}
.gpt3__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.gpt3__navbar-links_container p .link,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p .link {
    color: #fff;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin: 0rem  1rem;
    cursor: pointer;
    line-height: 25px;
    z-index: 1;
}
.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    width: 152px;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    height: 58px;
    left: 1153px;
    top: 55px;
    background: #ff4820;
    border: 0px;
    border-radius: 5px;
    font-size: 25px;
    padding: 0.5rem 1rem;
    outline: none;
    color: #fff;
    font-family: var(--font-family);
    cursor: pointer;
}
.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
    z-index: 1;
}
.gpt3__navbar-menu svg {
    cursor: pointer;
}
.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0px;
    margin-top: 3rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}
.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}
.gpt3__navbar-menu_container-links-sign {
    display: none;
}
@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }
    .gpt3__navbar-menu {
        display: flex;
    }
}
@media  screen and (max-width:700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}
@media screen and (max-width:550px) {
    .gpt3__navbar {
            padding: 2rem;
        }
    .gpt3__navbar-sign {
        display: none;
    }
    .gpt3__navbar-menu_container {
        top: 20px;
    }
    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}