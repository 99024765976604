.gpt3__possibility {
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem;
    position: relative;
}
.gpt3__possibility svg{
    position: absolute;
        top: 0px;
        width: 100%;
        padding: 0;
        left: 0;
}
.gpt3__possibility-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
}

.gpt3__possibility h1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 50px;
    font-weight: 900;
    text-transform: capitalize;
    margin-bottom: 5rem;
    margin-top: 150px;
}

.gpt3__possibility-image{
    display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-right: 3rem;
        margin-top: 3rem;
        color: #eee;
}

.gpt3__possibility-image h2{
    font-size: 50px;
}

.gpt3__possibility-image .skill{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.gpt3__possibility-image .skill .skills{
    background: rgba(0, 0, 0, 0.326);
        object-fit: cover;
        margin-top: 100px;
        margin-bottom: 50px;
        height: 250px;
        width: 250px;
        border-radius: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform-style: preserve-3d;
        transform: perspective(1000px);
        transition: all ease-in-out 0.5s;
}
.gpt3__possibility-image div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 2rem;
    align-items: center;
}
.gpt3__possibility-image p{
    margin-top: 10px;
    height: 30px;
    width: 100px;
    border-radius: 10px;
    border: 1px solid;
    box-shadow: #eee;
    align-items: center;
    text-align: center;
    font-size: 20px;
    margin-right: 15px;
    transform: translateZ(40px);
}
.gpt3__possibility-image img{
    width: 128px;
    transform: translateZ(40px);
}
.gpt3__possibility-content {
    display: flex;
        flex: 1;
        justify-content: center;
        align-items:center;
        flex-direction: column;
        margin-right: 3rem;
        margin-top: 7rem;
}
.gpt3__possibility-content img{
    position: absolute;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    cursor: pointer;
}
.gpt3__possibility-range{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.gpt3__possibility-range-skill{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
}
.gpt3__possibility-range-skill label,
.gpt3__possibility-range-skill .value{
    font-size: 30px;
    color: azure;
    margin: 20px;
    width: 10%;
}
.gpt3__possibility-range-skill input{
    color: black;
    width: 50%;
}

@media screen and (max-width: 1050px) {
    .gpt3__possibility {
        display: flex;
        flex-direction: column;
    }
        .gpt3__possibility-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    .gpt3__possibility-content{
    display: flex;
        flex-direction: column;
        margin-top: 100px;
            margin-bottom: 300px;
            margin-left: 10%;
            margin-right: 10%;
        max-width: 100%;
    }
    .gpt3__possibility-image{
        margin-top:  100px;
        margin-bottom: 300px;
        margin-left: 1%;
        width: 100%;
        height: 100%;
        }
                .gpt3__possibility-range-skill {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    width: 100%;
                }
    }

@media screen and (max-width: 490px) {
    .gpt3__possibility {
        display: flex;
        flex-direction: column;
    }
        .gpt3__possibility-container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
    .gpt3__possibility-content {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        margin-bottom: 300px;
        margin-left: 20%;
        margin-right: 7%;
        max-width: 60%;
    }

    .gpt3__possibility-image {
        margin-top: 100px;
        margin-bottom: 300px;
        margin-left: 1%;
        width: 100%;
        height: 100%;
    }
        .gpt3__possibility-range-skill {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
}