.gpt3__footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.gpt3__footer .cslid{
    position: absolute;
        top: 0px;
        width: 100%;
        padding: 0;
        left: 0;
}
.gpt3__footer-heading{
    width: 100%;
    text-align: center;
    margin-top:250px;
    margin-bottom: 1rem;
}
.gpt3__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    z-index: 2;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
    text-transform: capitalize;
    letter-spacing: normal;
}
.gpt3__footer-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #fff;
    margin-bottom: 20px;
    margin-top: 15px;
}
.gpt3__footer-btn p {
    text-align: center;
    width: 300px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    text-transform: capitalize;
    letter-spacing: normal;
}
.gpt3__footer-links{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align:center;
}
.gpt3__footer-links_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    font-family: var(--font-family);
    flex: 1;
}
.gpt3__footer-links_logo h1{
    font-size: 200px;
    font-weight: 900;
    text-transform: uppercase;
    border: 0 solid ;
    border-radius: 50%;
    margin: 5rem 0rem 1rem 0rem;
}
.gpt3__footer-links-div{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1;
}
.gpt3__footer-links-div h4{
    font-size: 30px;
    font-weight: 900;
    color: #fff;
    text-transform: capitalize;
}
.gpt3__footer-links-div .pageslink p .link{
    font-size: 25px;
    color: white;
    text-decoration: none;
    text-transform: capitalize;

}
.mylinks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mylinks a{
    width: 25%;
    color: white;
    text-decoration: none;
    margin: 10px;
}
.mylinks div{
        color: white;
        text-decoration: none;
}
.mylinks div .whatsapp{
    color: green;
    text-decoration: none;
    margin: 15px;
    border: 0px solid ;
    border-radius: 50%;
    background-color: rgba(0, 255, 68, 0.003);
}
.last{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.last p{
font-size: 20px;

}
