.gpt3__header{
    display: flex;
    padding: 3rem 6rem;
    position: relative;
    align-items: center;
    text-align: center;
}

.gpt3__header-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
}
.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    text-transform: capitalize;
    letter-spacing: 0.04rem;
    margin-top: 150px;
}
.gpt3__header-content h2{
    font-family: var(--font-family);
        font-weight: 800;
        font-size: 50px;
        line-height: 75px;
        text-transform: capitalize;
        letter-spacing: 0.04rem;
}
.gpt3__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
    text-align: center;
    align-items: center;
    letter-spacing: normal;
    color: white;
}
.gpt3__header-image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
    margin-top: 180px;
}
.gpt3__header-image img{
    height: 100%;
    width: 400px;
    z-index: 1;
}

@media screen and (max-width: 1050px){
.gpt3__header{
    flex-direction: column;
}
.gpt3__header-content{
    margin: 0 0 3rem;
}
}
@media screen and (max-width: 650px) {
    .gpt3__header h1 {
        font-size: 48px;
        line-height: 60px;
    }
    .gpt3__header-image h1{
    font-size: 200px;
    }
    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }
    .gpt3__header-content__people {
        flex-direction: column;
    }
    .gpt3__header-content__people p {
        margin: 0px;
    }
    .gpt3__header-content__input input,
    .gpt3__header-content__input buttn {
        font-size: 16px;
        line-height: 24px;
    }
}
    
@media screen and (max-width: 490px) {
    .gpt3__header h1 {
        font-size: 36px;
        line-height: 48px;
    }
        .gpt3__header-image h1 {
            font-size: 70px;
        }
    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input buttn {
        font-size: 12px;
        line-height: 16px;
    }
}