.sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}

.sections .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
}

.sections .title h1 {
    margin: 50px auto;
    font-size: 55px;
    font-weight: bolder;
    color: white;
}

.sections .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 500px;
    margin: 50px 30px;
    padding: 25px 20px;
    background-color: white;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    opacity: 0;
    transform: scale(0.1);
    transition: 0.5s ease-in-out;
    box-shadow: 0px 20px 60px rgba(7, 0, 0, 0.5);
}

.sections .section.active {
    opacity: 1;
    transform: scale(1);
}

.sections .section .icons {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 35px;
    background-color: #03071E;
}

.sections .section .icons i {
    color: #F6C445;
    font-size: 65px;
    font-weight: bolder;
}

.sections .section h1 {
    color: black;
    font-size: 35px;
    font-weight: 600;
    text-transform: capitalize;
}

.sections .section p {
    color: black;
    margin: 20px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Lumanosimo', cursive;
}

@media screen and (max-width:750px) {
    .sections .title h1 {
        font-size: 55px;
        font-weight: 700;
    }
}

@media screen and (max-width:350px) {
    .sections .title h1 {
        font-size: 35px;
        font-weight: 700;
    }

    .sections .section p {
        font-size: 15px;
        font-weight: 400;
    }

    .sections .section h1 {
        font-size: 25px;
        font-weight: 500;
    }
}