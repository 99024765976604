.blog_main{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.blog_main h1 {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 50px;
        font-weight: 900;
        text-transform: capitalize;
        margin-bottom: 5rem;
        margin-top: 150px;
}

.blog_main_work{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 700px;
    width: 400px;
    margin-bottom: 70px;
    margin-top: 70px;

}
.blog_main_work h2{
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 30px;
        font-weight: 900;
        text-transform: capitalize;
        margin-bottom: 2rem;
        position: absolute;
        top: 0;
}

.mySwiper {
    width: 100%;
    height: 500px;
    position: absolute;
    left: 33%;
    top: 29%;
    margin-left: -150px;
    margin-top: -150px;
}

.mySwiper .swiper-slide {
    background-position: center;
        background-size: cover;
        width: 320px;
        height: 500px;
        background: rgb(200, 214, 214);
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        -webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0006);
        
}

.swiper-slide img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
}
.blog_main_worksf{
    position: relative;
        margin: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 900px;
        width: 100%;
        margin-bottom: 0px;
        overflow: hidden;
}
.blog_main_worksf h2{
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 30px;
        font-weight: 900;
        text-transform: capitalize;
        margin-bottom: 2rem;
        top: 0;
}
.blog_main_worksf .cardSwiper{
    width: 100%;
        margin-top: 50px;
        height: 800px;
        display: flex;
        flex-direction: row;
        position: absolute;
}
.blog_main_worksf .cardSwiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 320px;
    height: 450px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        -webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0006);
}
.blog_main_worksf .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
}