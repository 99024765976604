.artical_section{
    position: relative;
    display: flex;
    padding: 2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.artical_part{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
}
.artical_small_part{
    position: relative;
    width: 400px;
    height: 500px;
    margin: 50px 30px;
    padding: 25px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 50px;
    transition: 0.6s;
    box-shadow: 0px 20px 60px rgba(7, 0, 0, 0.5);
}

.artical_small_part img{
    position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 15px;
}
.atical_content{
    position: absolute;
    background-color: white;
    width: 70%;
    border-radius: 25px;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.5s ease-in-out;
    overflow: hidden;
    margin: 15px auto;
}
.atical_content h1{
    text-transform: capitalize;
    font-size: 30px;
    transition: 0.75s ease-in-out;
    transform: translateY(400px);
}
.atical_content p{
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    transition: 0.75s ease-in-out;
    transform: translateY(400px);
}

.artical_small_part:hover .atical_content{
    transform: scaleY(1);
}
.artical_small_part:hover .atical_content h1{
    transform: translateY(0px);
    transition-delay: 0.5s;
}
.artical_small_part:hover .atical_content p{
    transform: translateY(0px);
    transition-delay: 0.7s;
}